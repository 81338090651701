import React,{useEffect} from 'react'
import './category.css'
import { Link } from 'react-router-dom'
import {useSelector } from 'react-redux'
const Kids = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      useEffect(() => {
        const canonicalUrl =`https://rampvalk.com/kids`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
  return (
    <div className='categoryPage'>
    <div className="collections margin-section">
    <Link to={`/collections/${collectionState[16]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[16]?.images[1]?.url)} alt="" />
          <h2>{collectionState[16]?.category}</h2>
      </div></Link>
      <Link to={`/collections/${collectionState[13]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[13]?.images[1]?.url)} alt="" />
          <h2>{collectionState[13]?.category}</h2>
      </div></Link>
      {/* <Link to={`/collections/${collectionState[36]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[36]?.images[1]?.url)} alt="" />
          <h2>{collectionState[36]?.category}</h2>
      </div></Link>
      
      <Link to={`/collections/${collectionState[24]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[24]?.images[1]?.url)} alt="" />
          <h2>{collectionState[24]?.category}</h2>
      </div></Link>
      <Link to={`/collections/${collectionState[34]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[34]?.images[1]?.url)} alt="" />
          <h2>{collectionState[34]?.category}</h2>
      </div></Link> */}
      <Link to={`/collections/${collectionState[21]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[21]?.images[1]?.url)} alt="" />
          <h2>{collectionState[21]?.category}</h2>
      </div></Link>
      {/* <Link to={`/collections/${collectionState[32]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[32]?.images[1]?.url)} alt="" />
          <h2>Kid's Hoodie</h2>
      </div>
</Link><Link to={`/collections/${collectionState[29]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[29]?.images[1]?.url)} alt="" />
          <h2>{collectionState[29]?.category}</h2>
      </div></Link> */}

    </div>
  </div>
  )
}

export default Kids
