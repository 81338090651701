import React ,{useEffect} from 'react'
import './blog.css'
import { useDispatch, useSelector } from 'react-redux'
import {getAllBlogs } from '../../features/blogs/blogSlice';
import { Link } from 'react-router-dom';
const Blog = () => {
  const blogState=useSelector((state)=>state?.blog?.blog)
    const dispatch=useDispatch();
    useEffect(()=>{
        getBlogs()
    },[])
    const getBlogs=()=>{
        dispatch(getAllBlogs())
    }
    const blogs=blogState? blogState :[]
    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };
    useEffect(() => {
      const canonicalUrl =`https://rampvalk.com/blogs`;
      let canonicalLink = document.querySelector('link[rel="canonical"]');
  
      if (canonicalLink) {
        canonicalLink.setAttribute('href', canonicalUrl);
      } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
      }
  }, []);
  return (
    <div className='margin-section' style={{marginTop:'100px'}}>
      <p className="section-heading">Our Blogs</p>
      <div className="blogs">
        {
         blogs?.map((item,index)=>{
            return(
              <Link to={`/blogs/news/${item?.handle}`} className='blog-card'>

              <div className="blog" key={index}>
                <img src={modifyCloudinaryUrl(item?.images[0]?.url)} alt={item?.title} />
                <p className='title'> {item?.title}</p>
        <p className='desc' variant="body2" color="text.secondary" style={{height:'100px',overflow:'hidden',textOverflow:'ellipsis'}} dangerouslySetInnerHTML={{ __html: item?.description }}>
        </p>
       <button size="small">Learn More</button>
                </div>
                </Link>

                
            )
          })
        }
        


      </div>
      
    </div>

  )
}

export default Blog
