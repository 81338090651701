import React, { useEffect,useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'
import {useSelector } from 'react-redux'
import {toast} from 'react-toastify'

const Wishlist = () => {
  const bannerState=useSelector((state)=>state?.banner?.banner)

const [wishlist,setWishlist]=useState([])
    useEffect(() => {
      // Retrieve cart items from localStorage
      const cartFromStorage = JSON.parse(localStorage.getItem('wishlist')) || [];
      setWishlist(cartFromStorage);
  }, []);

    const removeFromWishlist=(id)=>{
      const cardDetails = JSON.parse(localStorage.getItem('wishlist')) || [];
      const updatedWishlistDetails = cardDetails.filter(item => item.productId !== id);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlistDetails));
        setWishlist(updatedWishlistDetails)
      setTimeout(()=>{
       toast.success("Removed")
      },300)
    }
    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };
  return (
    <div className=''>
        <div className="category-banner">
        <img src={modifyCloudinaryUrl(bannerState[36]?.images[0]?.url)} alt={bannerState[37]?.alt} />
      </div>
            <div className="products-listing margin-section">
        <p className="section-heading">Wishlist</p>
        <div className="product-list ">
          {
            wishlist.length===0 && <div>NO DATA</div>
          }

{
  wishlist?.map((item,index)=>{
    return(
      <div className="product-card" key={index}>
      <Link to={`/products/${item?.product?.handle}`}>
      <div className="product-img">
        <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" className="product-img1"/>
      </div>
      </Link>
      <p className="wish-icon" onClick={()=>{removeFromWishlist(item?.product?._id)}}><CloseIcon className="cart-icon"/></p>
      <div className="product-content">
        <Link to={`/products/${item?.product?.handle}`}>
        <p className="title">{item?.product?.title}</p>
        </Link>
<div className="wish">
<div>
<p className="price">&#8377;{item?.product?.price}</p>
<p className="sale-price">&#8377;{item?.product?.price*2}</p>
</div>
<div>
<CloseIcon className="cart-icon" onClick={()=>{removeFromWishlist(item?.productId)}}/>
</div>
</div>

      </div>
    </div>
    )
  })
}
         

        </div>
      </div>
    </div>
  )
}

export default Wishlist
