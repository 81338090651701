import React,{useEffect} from 'react'
import './category.css'
import {Link} from 'react-router-dom'
import {useSelector } from 'react-redux'
const Men = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      useEffect(() => {
        const canonicalUrl =`https://rampvalk.com/men`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
  return (
    <div className='categoryPage'>
      <div className="collections margin-section">
      <Link to={`/collections/${collectionState[2]?.handle}`} >
        <div className="collection-card">
            
            <img src={modifyCloudinaryUrl(collectionState[2]?.images[1]?.url)} alt="" className='col-img'/>
            <h2>{collectionState[2]?.category}</h2>
        </div>
        </Link>
        <Link to={`/collections/${collectionState[3]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[3]?.images[1]?.url)} alt="" />
            <h2>{collectionState[3]?.category}</h2>
        </div>
        </Link>
        {/* <Link to={`/collections/${collectionState[43]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[43]?.images[1]?.url)} alt="" />
            <h2>{collectionState[43]?.category}</h2>
        </div>
        </Link> */}
       
        {/* <Link to={`/collections/${collectionState[0]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[0]?.images[1]?.url)} alt="" />
            <h2>{collectionState[0]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[6]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[6]?.images[1]?.url)} alt="" />
            <h2>{collectionState[6]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[5]?.handle}`} >

        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[5]?.images[1]?.url)} alt="" />
            <h2>{collectionState[5]?.category}</h2>
        </div>
        </Link> */}
        <Link to={`/collections/${collectionState[1]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[1]?.images[1]?.url)} alt="" />
            <h2>{collectionState[1]?.category}</h2>
        </div>
        </Link>
        
        {/* <Link to={`/collections/${collectionState[10]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[10]?.images[1]?.url)} alt="" />
            <h2>{collectionState[10]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[8]?.handle}`}>
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[8]?.images[1]?.url)} alt="" />
            <h2>{collectionState[8]?.category}</h2>
        </div>
        </Link> */}
        <Link to={`/collections/${collectionState[17]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[17]?.images[1]?.url)} alt="" />
            <h2>{collectionState[17]?.category}</h2>
        </div>
        </Link>
       
        {/* <Link to={`/collections/${collectionState[4]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[4]?.images[1]?.url)} alt="" />
            <h2>{collectionState[4]?.category}</h2>
        </div>
        </Link> */}


        {/* <Link to={`/collections/${collectionState[20]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[20]?.images[1]?.url)} alt="" />
            <h2>{collectionState[20]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[7]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[7]?.images[1]?.url)} alt="" />
            <h2>{collectionState[7]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[11]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[11]?.images[1]?.url)} alt="" />
            <h2>{collectionState[11]?.category}</h2>
        </div>
        </Link> */}
        {/* <Link to={`/collections/${collectionState[19]?.handle}`} >
        <div className="collection-card">
            <img src={modifyCloudinaryUrl(collectionState[19]?.images[1]?.url)} alt="" />
            <h2>{collectionState[19]?.category}</h2>
        </div>
        </Link> */}
      </div>
    </div>
  )
}

export default Men
