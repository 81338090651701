import React,{useEffect}  from 'react'
import './category.css'
import { Link } from 'react-router-dom'
import {useSelector } from 'react-redux'
const Women = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      useEffect(() => {
        const canonicalUrl =`https://rampvalk.com/women`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
    return (
        <div className='categoryPage'>
            <div className="collections margin-section">
            <Link to={`/collections/${collectionState[18]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[18]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[18]?.category}</h2>
                </div></Link>
                <Link to={`/collections/${collectionState[22]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[22]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[22]?.category}</h2>
                </div></Link>
                <Link to={`/collections/${collectionState[12]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[12]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[12]?.category}</h2>
                </div></Link>
                {/* <Link to={`/collections/${collectionState[33]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[33]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[33]?.category}</h2>
                </div></Link>
                <Link to={`/collections/${collectionState[23]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[23]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[23]?.category}</h2>
                </div></Link>
                <Link to={`/collections/${collectionState[35]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[35]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[35]?.category}</h2>
                </div></Link> */}
                <Link to={`/collections/${collectionState[38]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[38]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[38]?.category}</h2>
                </div></Link>
                
                {/* <Link to={`/collections/${collectionState[39]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[39]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[39]?.category}</h2>
                </div></Link>
                <Link to={`/collections/${collectionState[41]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[41]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[41]?.category}</h2>
                </div></Link><Link to={`/collections/${collectionState[28]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[28]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[28]?.category}</h2>
                </div></Link><Link to={`/collections/${collectionState[30]?.handle}`}>
                <div className="collection-card">
                    <img src={modifyCloudinaryUrl(collectionState[30]?.images[1]?.url)} alt="" />
                    <h2>{collectionState[30]?.category}</h2>
                </div></Link> */}
            </div>
        </div>
    )
}

export default Women
